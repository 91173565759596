import 'slick-carousel';

export default function initSingleImageSlider() {
  const slider = document.querySelector('.js-single-image-slider');

  if(!slider) {
    return;
  }

  jQuery(slider).slick({
    rows: 0,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: true,
    arrows: true,
    dots: true,
    pauseOnHover: true,
  });

  function updateSlickDotsPosition() {
    if (window.innerWidth < 768) {
      const imgBlock = slider.querySelector('.slide-layout .img-block');
      const slickDots = slider.querySelector('.slick-dots');

      if (imgBlock && slickDots) {
        const imgBlockHeight = imgBlock.offsetHeight;
        slickDots.style.top = `${imgBlockHeight}px`;
      }
    } else {
      const slickDots = slider.querySelector('.slick-dots');
      if (slickDots) {
        slickDots.style.top = '';
      }
    }
  }

  setTimeout(function() {
    updateSlickDotsPosition()
  }, 100)

  window.addEventListener('resize', function() {
    setTimeout(function() {
      updateSlickDotsPosition()
    }, 100)
  })

  slider.addEventListener('setPosition', function() {
    setTimeout(function() {
      updateSlickDotsPosition()
    }, 100)
  })
}
