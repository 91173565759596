import {Panzoom} from '@fancyapps/ui/dist/panzoom/panzoom.esm.js';
import {Pins} from '@fancyapps/ui/dist/panzoom/panzoom.pins.esm.js';
import {Fancybox} from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import Handlebars from 'handlebars';
import 'slick-carousel';

Handlebars.registerHelper('eq', function (a, b) {
	return a === b;
});

const isTouchDevice = /Windows Phone/.test(navigator.userAgent) || ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;

export default class CustomMap {
	constructor(elem, options) {
		this.options = {
			mapCaption: '.caption',
			mapHolder: '.map-holder',
			modalContent: '.modal-content',
			modalTitle: '.modal-title',
			mediaModalHolder: '.media-modal-holder',
			btnPrev: '.btn-prev',
			btnNext: '.btn-next',
			mapPinClass: 'map-pin',
			modalSliderClass: 'modal-slider',
			mediaModalSliderClass: 'media-modal-slider',
			modalActiveClass: 'modal-open',
			hiddenClass: 'hidden',
			...options
		};

		this.holder = elem;
		this.holder.CustomMap = this;

		this.init();
	}

	init() {
		this.findElements();
		this.attachEvents();
		this.makeCallback('onInit', this);
	}

	findElements() {
		this.mapCaption = this.holder.querySelector(this.options.mapCaption);
		this.mapHolder = this.holder.querySelector(this.options.mapHolder);
		this.modalContent = this.holder.querySelector(this.options.modalContent);
		this.mediaModalHolder = this.holder.querySelector(this.options.mediaModalHolder);
		this.modalTitle = this.holder.querySelector(this.options.modalTitle);
		this.btnPrev = this.holder.querySelector(this.options.btnPrev);
		this.btnNext = this.holder.querySelector(this.options.btnNext);
		this.pinTemplate = this.holder.dataset.pinTemplate;
		this.popupTemplate = this.holder.dataset.popupTemplate;
		this.modalTemplate = this.holder.dataset.modalTemplate;
		this.mediaModalTemplate = this.holder.dataset.mediaModalTemplate;
		this.templateModal = null;
		this.panzoom = null;
		this.pins = [];
		this.modalsData = [];
		this.currentModal = 0;
		this.hash = window.location.hash;

		if (this.holder.dataset.pins) {
			this.pinsData = JSON.parse(this.holder.dataset.pins);
		}
	}

	attachEvents() {
		if (this.pinsData) {
			for (let i = 0; i < this.pinsData.length; i++) {
				const categories = this.pinsData[i].categories;
				let additionalClasses = '';

				if (categories && categories.length > 0) {
					for (let j = 0; j < categories.length; j++) {
						additionalClasses += ` ${categories[j]}`;
					}

					this.pinsData[i].classes = additionalClasses;
				}

				if (this.pinTemplate) {
					this.mapHolder.insertAdjacentHTML('beforebegin', this.createElement(this.pinsData[i], this.pinTemplate));
				}

				if (categories && categories.length > 0 && !categories.includes('sold')) {
					if (this.popupTemplate) {
						this.holder.insertAdjacentHTML('beforeend', this.createElement(this.pinsData[i], this.popupTemplate));
					}

					this.modalsData.push(this.pinsData[i]);
				}
			}

			this.pins = this.holder.querySelectorAll(`.${this.options.mapPinClass}`);

			this.popupButtons = this.holder.querySelectorAll('.show-modal');

			if (this.popupButtons) {
				this.popupButtons.forEach((btn) => {
					btn.addEventListener('click', (e) => {
						e.preventDefault();
						const pinData = this.pinsData.find((pin) => pin.popupID === e.target.dataset.popup.slice(1));

						this.createModal(pinData);
					});
				});
			}

			if (this.modalTemplate) {
				const elementModal = document.querySelector(this.modalTemplate);
				const sourceModal = elementModal.innerHTML;

				this.templateModal = Handlebars.compile(sourceModal);

				this.pins.forEach((pin) => {
					pin.addEventListener('click', (e) => {
						e.preventDefault();
						const pinData = this.pinsData.find((pin) => pin.popupID === e.target.dataset.popup.slice(1));

						this.createModal(pinData);
					});
				});
			}
		}

		if (this.btnPrev && this.btnNext) {
			this.btnPrev.addEventListener('click', () => {
				this.prevModal();
			});

			this.btnNext.addEventListener('click', () => {
				this.nextModal();
			});
		}

		this.initZoomMap();

		if (this.hash) {
			const popupID = this.getHashValueByKey(this.hash, 'popup');

			if (popupID) {
				const pinData = this.pinsData.find((pin) => pin.popupID === popupID);

				if (pinData) {
					setTimeout(() => {
						this.createModal(pinData);
					});
				}
			}
		}

		document.addEventListener('click', (e) => {
			if (!e.target.closest('.map-pin') && !e.target.closest(this.options.modalContent) && !e.target.closest('.media-modal') && !e.target.closest('.show-modal') && !e.target.closest('.modal-holder')) {
				this.holder.classList.remove(this.options.modalActiveClass);
				document.body.classList.remove('homesite-modal-active');

				if (this.panzoom && isTouchDevice) {
					this.panzoom.attachEvents();
				}
			}
		});
	}

	createElement(data, tmpl) {
		const tmplElement = document.querySelector(tmpl);
		const source = tmplElement.innerHTML;
		const template = Handlebars.compile(source);

		return template(data);
	}

	prevModal() {
		this.currentModal--;

		if (this.currentModal < 0) {
			this.currentModal = this.modalsData.length - 1;
		}

		this.switchModals();
	}

	nextModal() {
		this.currentModal++;

		if (this.currentModal >= this.modalsData.length) {
			this.currentModal = 0;
		}

		this.switchModals();
	}

	switchModals() {
		this.createModal(this.modalsData[this.currentModal]);
	}

	createModal(data) {
		if (!this.templateModal) return;

		const modalHTML = this.templateModal(data);

		this.currentModal = this.modalsData.indexOf(data);
		this.modalContent.innerHTML = modalHTML;
		this.holder.classList.add(this.options.modalActiveClass);
		jQuery('body').addClass('homesite-modal-active');

		this.modalTitle.innerHTML = data.title;

		this.initSlider(this.modalContent.querySelector(`.${this.options.modalSliderClass}`), {
			rows: 0,
			slidesToShow: 1,
			arrows: false,
			fade: false,
			autoplay: false,
			autoplaySpeed: 2000,
			infinite: false,
			dots: true
		});

		this.mediaModalHolder.innerHTML = '';

		this.mediaModalHolder.insertAdjacentHTML('beforeend', this.createElement(data, this.mediaModalTemplate));

		this.initFancybox();

		if (this.panzoom && isTouchDevice) {
			this.panzoom.detachEvents();
		}

		this.initSlider(this.mediaModalHolder.querySelector(`.${this.options.mediaModalSliderClass}`), {
			rows: 0,
			slidesToShow: 1,
			arrows: true,
			fade: false,
			autoplay: false,
			infinite: true,
			dots: false,
			appendArrows: jQuery('.media-modal-slider-controls')
		});

		const btnClose = this.modalContent.querySelector('.btn-close');

		if (btnClose) {
			btnClose.addEventListener('click', () => {
				this.holder.classList.remove(this.options.modalActiveClass);
				jQuery('body').removeClass('homesite-modal-active');

				if (this.panzoom && isTouchDevice) {
					this.panzoom.attachEvents();
				}
			});
		}
	}

	initSlider(slider, options) {
		const opt = {
			rows: 0,
			arrows: false,
			fade: false,
			autoplay: false,
			infinite: false,
			dots: true,
			...options
		};

		jQuery(slider).slick(opt);
	}

	initFancybox() {
		Fancybox.bind('.zoom', {
			dragToClose: false,
			hideScrollbar: false,
			mainClass: 'homesite-modal',
			on: {
				reveal: () => {
					setTimeout(() => {
						window.dispatchEvent(new Event('resize'));

						if (jQuery('.media-modal-slider').length) {
							jQuery('.media-modal-slider').slick('setPosition');
						}
					}, 100);
				}
			},
			tpl: {
				closeButton: '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}"><span class="icon-close"></span></button>'
			}
		});
	}

	initZoomMap() {
		this.panzoom = new Panzoom(this.holder, {
			click: false,
			wheel: false,
			panOnlyZoomed: true,
			rubberband: false,
			bounce: false,
			minScale: 0.5,
			on: {
				touchStart: (instance) => {
					setTimeout(() => {
						if (this.mapCaption) {
							this.mapCaption.classList.add(this.options.hiddenClass);
						}
					}, 1500);
				}
			}
		}, {
			Pins
		});
	}

	getHashValueByKey(hash, key) {
		hash = hash.startsWith('#') ? hash.slice(1) : hash

		const pairs = hash.split('&')

		for (let pair of pairs) {
			const [currentKey, value] = pair.split('=')

			if (decodeURIComponent(currentKey) === key) {
				return decodeURIComponent(value || '')
			}
		}

		return null
	}

	makeCallback(callbackName, ...args) {
		if (typeof this.options[callbackName] === 'function') {
			this.options[callbackName].apply(this, args);
		}
	}
}