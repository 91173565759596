import 'slick-carousel';

export default function initTabsSlider() {
  const tabsNavSlider = jQuery('.js-tabs-nav-slider');
  const tabsSlider = jQuery('.js-tabs-slider');

  jQuery(tabsNavSlider).slick({
    rows: 0,
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.js-tabs-slider',
    focusOnSelect: true,
    appendArrows: jQuery('.tabs-nav-slider-wrapper'),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });

  jQuery(tabsSlider).slick({
    rows: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.js-tabs-nav-slider',
    draggable: false,
  });
}
