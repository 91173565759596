export default function initHeroBgParallax() {
  const bgBlock = document.querySelector('.hero .bg-block')

  if (!bgBlock) {
    return
  }

  window.addEventListener('scroll', function() {
    const scrollPosition = window.pageYOffset
    const parallaxSpeed = 0.5
    bgBlock.style.transform = `translate(-50%, calc(-50% + ${scrollPosition * parallaxSpeed}px))`
  })
}
