export default function initStackedImagesParallax() {
  const imageHolders = document.querySelectorAll('.image-holder');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        window.addEventListener('scroll', () => parallaxEffect(entry.target));
      } else {
        window.removeEventListener('scroll', () => parallaxEffect(entry.target));
      }
    });
  });

  imageHolders.forEach(holder => observer.observe(holder));

  function parallaxEffect(holder) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const holderRect = holder.getBoundingClientRect();
    const holderOffsetTop = holderRect.top + scrollTop;
    const windowHeight = window.innerHeight;

    const imageBlockLarge = holder.querySelector('.image-block.large');
    const imageBlockSmall = holder.querySelector('.image-block.small');

    if (imageBlockLarge && imageBlockSmall) {
      const maxTransformValue = 40;
      const distanceFromViewportTop = holderRect.top;
      const distanceToViewportCenter = windowHeight / 2 - distanceFromViewportTop;
      const scrollEffect = Math.min(maxTransformValue, Math.max(-maxTransformValue, distanceToViewportCenter * 0.1));

      if (holder.classList.contains('topleft')) {
        imageBlockLarge.style.transform = `translateY(${-scrollEffect}px)`;
        imageBlockSmall.style.transform = `translateY(${scrollEffect}px)`;
      } else {
        imageBlockLarge.style.transform = `translateY(${scrollEffect}px)`;
        imageBlockSmall.style.transform = `translateY(${-scrollEffect}px)`;
      }
    }
  }
}
