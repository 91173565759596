/* eslint-disable */
export default class HoverJs {
  constructor(holder, options) {
    const defaultOptions = {
      activeClass: 'hover-js'
    };
    const optionsValid = typeof options === 'object' || typeof options === 'undefined';
    this.options = optionsValid ? { ...defaultOptions, ...options } : defaultOptions;

    this.isTouch = !!('ontouchstart' in document.documentElement);
    this.holder = holder;

    this.clickHandler = this.clickHandler.bind(this);
    this.clickOutSideHandker = this.clickOutSideHandker.bind(this);
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  init() {
    if (!this.holder || !!this.holder.HoverJs) return;

    this.holder.HoverJs = this;
    this.findElements();
    this.isTouch ? this.initTouchEvent() : this.initEvent();
    this.makeCallBack('onInit');
  }

  findElements() {
    this.itemsParent = [...this.holder.children];

    this.itemsParent.forEach(item => {
      const hasDrop = item.querySelector('.slide');

      item.data = {
        hasDrop
      };
    });

    const links = this.itemsParent.map(child => {
      return [...child.children].find(link => {
        return link.tagName.toUpperCase() === 'A' ||  link.tagName.toUpperCase() === 'BUTTON'
      });
    }).filter(el => el);
    this.itemsLink = links;
  }

  initTouchEvent() {
    this.itemsLink.forEach(element => {
      element.addEventListener('click', this.clickHandler);
    });

    document.addEventListener('click', this.clickOutSideHandker);
  }

  initEvent() {
    this.itemsParent.forEach(element => {
      element.addEventListener('mouseenter', this.mouseEnterHandler);
      element.addEventListener('mouseleave', this.mouseLeaveHandler);
    });
  }

  mouseEnterHandler(e) {
    this.addHover(e.target);
  }

  mouseLeaveHandler(e) {
    this.removeHover(e.target);
  }

  clickHandler(e) {
    const target = e.target;
    const parent = target.closest('li');

    if (!parent.classList.contains(this.options.activeClass)) {
      if (parent.data.hasDrop) {
        e.preventDefault();
      }

      this.addHover(parent);
      this.removeRestHover(parent);
    }
  }

  clickOutSideHandker(e) {
    if (!e.target.closest('li')) {
      this.removeRestHover();
    }
  }

  addHover(item) {
    item.classList.add(this.options.activeClass);
    var elem = item.querySelector('.img-block');

    if(elem) {
      elem.style.zIndex = '1';
      let opacity = 0;

      function fadeIn() {
        if (opacity < 1) {
          opacity += 0.05;
          elem.style.opacity = opacity;
          requestAnimationFrame(fadeIn);
        } else {
          elem.style.opacity = 1;
        }
      }

      fadeIn();
    }
  }

  removeHover(item) {
    item.classList.remove(this.options.activeClass);
    const elem = item.querySelector('.img-block');

    if(elem) {
      let opacity = 1;

      function fadeOut() {
        if (opacity > 0) {
          opacity -= 0.05;
          elem.style.opacity = opacity;
          requestAnimationFrame(fadeOut);
        } else {
          elem.style.opacity = 0;
          elem.style.zIndex = 'auto';
        }
      }

      fadeOut();
    }
  }

  removeRestHover(item) {
    this.parentArr = item ? [item] : [];
    let itemOrParent = true;

    if (item) this.searchParent(item);

    this.itemsParent.forEach(element => {
      if (element.classList.contains(this.options.activeClass)) {
        itemOrParent = true;

        this.parentArr.forEach(item => {
          if (element === item) itemOrParent = false;
        });

        if (itemOrParent) this.removeHover(element);
      }
    });
  }

  searchParent(curentParent) {
    const parent = curentParent.parentElement.closest('li');

    if (!parent) return false;

    this.parentArr.push(parent);
    this.searchParent(parent);
  }

  makeCallBack(functionName, ...environs) {
    if (typeof this.options[functionName] === 'function') {
      this.options[functionName](environs.length ? environs : this);
    }
  }

  destroy() {
    this.removeRestHover();
    this.holder.HoverJs = '';

    this.itemsParent.forEach(element => {
      element.removeEventListener('mouseenter', this.mouseEnterHandler);
      element.removeEventListener('mouseleave', this.mouseLeaveHandler);
      this.removeHover(element);
    });

    this.itemsLink.forEach(element => {
      element.removeEventListener('click', this.clickHandler);
    });

    document.removeEventListener('click', this.clickOutSideHandker);

    this.makeCallBack('onDestroy');
  }
}
