export default function initDropDownClasses() {
  jQuery('.main-nav li').each(function() {
    var item = jQuery(this);
    var drop = item.find('.slide');
    var link = item.find('a, button').eq(0);
    if (drop.length) {
      item.addClass('has-drop-down');
      if (link.length) link.addClass('has-drop-down-opener');
    }
  });
}
