import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import './utils/vh'
import './utils/vw'
import ResizeHandler from './utils/resize-active'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

import initMobileNav from './components/initMobileNav'
import initScrollDown from './components/initScrollDown'
import initHeroBgParallax from './components/initHeroBgParallax'
import initTabsSlider from './components/initTabsSlider'
import initSingleImageSlider from './components/initSingleImageSlider'
import initMapPopups from './components/initMapPopups'
import initCustomMap from './components/initCustomMap'
import { initCustomForms, initCustomSelects } from './components/initCustomSelects'
import initGeneralPopup from './components/initGeneralPopup'
import initDropDownClasses from './components/initDropDownClasses'
import initMainNavAccordion from './components/initMainNavAccordion'
import initContentAccordion from './components/initContentAccordion'
import initStackedImagesParallax from './components/initStackedImagesParallax'
import initMultiImageSlider from './components/initMultiImageSlider'
import initHoverJs from './components/initHoverJs'
import initResourcesLinks from './components/initResourcesLinks'

const resizeHandler = new ResizeHandler()

window.addEventListener('message', function(event) {
  if (event.data && event.data.type === 'hsFormCallback') {
    if (event.data.eventName == 'onFormError') {
      jcf.destroyAll(jQuery('.hs-form-' + event.data.id));
    }

    if (event.data.eventName == 'onFormFailedValidation') {
      jcf.replaceAll(jQuery('.hs-form-' + event.data.id));
    }
  }
});

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()

  initMobileNav()
  initScrollDown()
  initHeroBgParallax()
  initTabsSlider()
  initSingleImageSlider()
  initCustomMap()
  initMapPopups()
  initCustomSelects()
  initCustomForms()
  initGeneralPopup()
  initDropDownClasses()
  initMainNavAccordion()
  initContentAccordion()
  initStackedImagesParallax()
  initMultiImageSlider()
  initHoverJs()
  initResourcesLinks()

  setTimeout(() => {
    document.body.classList.add('page-loaded')
    jcf.replaceAll('.hs-form')
  }, 2000);
})