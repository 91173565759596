import Accordion from '../plugins/contentAccordionPlugin';

// init accordions
export default function initContentAccordion() {
  const accordions = document.querySelectorAll('.js-faq-accordion .accordion-item');

  if(!accordions.length) {
    return;
  }

  accordions.forEach((accordionEl) => {
    new Accordion(accordionEl);
  });
}
