export default function initScrollDown() {
  const btn = document.querySelector('.js-scroll-to-next')

  if(!btn) {
    return
  }

  btn.addEventListener('click', function() {
    const nextSection = document.querySelector('.hero').nextElementSibling
    nextSection.scrollIntoView({ behavior: 'smooth' });
  })
}
