import '../plugins/openClosePlugin';
import '../plugins/customFormsPlugin';

function initCustomForms() {
  jcf.setOptions('Select', {
    wrapNative: false,
    wrapNativeOnMobile: false,
    fakeDropInBody: true
  });

  jcf.replaceAll();
}

function initCustomSelects() {
  const win = jQuery(window);
  let ifTimeOut = false;

  checkIsFormLoad();

  setTimeout(() => {
    ifTimeOut = true;
  }, 30000);

  function checkIsFormLoad() {
    if (ifTimeOut) return;

    setTimeout(() => {
      if (jQuery('.hs-form').length) {
        initSelects();

        jQuery('.hs-form select').closest('.input').addClass('has-jcf-field');
      } else {
        checkIsFormLoad();
      }
    }, 500);
  }

  function initSelects() {
    const selectedClass = 'selected';
    const defaultText = 'Interested in homesite number...';

    jQuery('.hs-form .inputs-list').has('[name="interested_in_homesite_number"]').each((i, item) => {
      const list = jQuery(item);
      const form = list.closest('.hs-form');
      const checkboxes = list.find(':checkbox');

      list.wrap('<div class="custom-select"></div>');
      jQuery('<a href="#" class="opener">' + defaultText + '</a>').insertBefore(list);
      list.wrap('<div class="select-drop"></div>').wrap('<div class="jcf-scrollable"></div>');

      const holder = list.closest('.custom-select');
      const opener = holder.find('.opener');

      holder.openClose({
        activeClass: 'active',
        opener: '.opener',
        slider: '.select-drop',
        hideOnClickOutside: true,
        animSpeed: 0,
        effect: 'none'
      });

      checkboxes.on('change', updateOpenerTitle);

      function updateOpenerTitle() {
        let openerTitle = '';

        checkboxes.each((i, item) => {
          const input = jQuery(item);
          const textElem = input.siblings('span');

          if (input.is(':checked') && textElem.length) {
            if (openerTitle !== '') {
              openerTitle += ', ' + textElem.text();
            } else {
              openerTitle += textElem.text();
            }
          }
        });

        if (openerTitle.trim() !== '') {
          opener.text(openerTitle);
        }

        if (!checkboxes.filter(':checked').length) {
          opener.text(defaultText);
          holder.removeClass(selectedClass);
        } else {
          holder.addClass(selectedClass);
        }
      }

      function resizeHandler() {
        opener.css({
          width: form.outerWidth()
        });
      }

      updateOpenerTitle();
      resizeHandler();

      win.on('resize orientationchange', resizeHandler);
    });
  }
}

export { initCustomForms, initCustomSelects }
