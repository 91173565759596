import { Fancybox } from "@fancyapps/ui";

export default function initGeneralPopup() {
  const openers = document.querySelectorAll('a[href^="#"]');

  openers.forEach(function(opener) {
    const targetId = opener.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      opener.setAttribute('data-fancybox', '');
    }
  });

  Fancybox.bind("[data-fancybox]:not(.zoom)", {
    //hideScrollbar: false,
    tpl: {
      closeButton:
    '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}"><span class="icon-close"></span></button>',
    }
  });
}
