export default function initResourcesLinks() {
  const links = document.querySelectorAll('.resource-item .link');

  if(!links.length) {
    return;
  }

  links.forEach(link => {
    let clickTimeout = null;

    link.addEventListener('click', function(event) {
      event.preventDefault();

      if (clickTimeout !== null) {
        clearTimeout(clickTimeout);
        clickTimeout = null;
        triggerDownload(link);
      } else {
        clickTimeout = setTimeout(() => {
          window.open(link.href, '_blank');
          clickTimeout = null;
        }, 300);
      }
    });

    function triggerDownload(link) {
      const url = new URL(link.href);

      let downloadUrl = link.href;
      let fileName = url.pathname.split('/').pop();

      if (url.hostname === 'drive.google.com') {
        const fileId = url.pathname.split('/')[3];
        downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
      }

      if (url.hostname === 'www.dropbox.com') {
        downloadUrl = url.href.replace('?dl=0', '?dl=1');
        fileName = url.searchParams.get('filename') || fileName;
      }

      const tempLink = document.createElement('a');
      tempLink.href = downloadUrl;
      tempLink.download = fileName;
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  });
}