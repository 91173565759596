import Swiper from 'swiper';
import { Navigation, Pagination, A11y, Parallax, EffectFade } from 'swiper/modules';
import '../utils/responsiveHelper';

export default function initMultiImageSlider() {
  const sliders = [...document.querySelectorAll('.js-multi-slider')];

  if (!sliders.length) {
    return;
  }

  sliders.forEach(function(slider, i) {
    const slideCount = jQuery(slider).find('.slide').length;
    let isLastSlide = false;
    let swiper = null;
    let isMobile = false;

    let options = {
      slidesPerView: 1,
      speed: 600,
      modules: [Navigation, Pagination, A11y, Parallax, EffectFade],
      spaceBetween: 40,
      speed: 200,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          speed: 300,
        },
        1366: {
          slidesPerView: 'auto',
          spaceBetween: 0,
        }
      },
      on: {
        transitionEnd: function(swiper) {
          if (swiper.isEnd) {
            isLastSlide = true;
          }
        },
        touchMove: function(swiper) {
          if (swiper.isEnd && isLastSlide && !isMobile) {
            setTimeout(() => {
              if (swiper.activeIndex === slideCount - 1) {
                swiper.slideTo(0);
                isLastSlide = false;
              }
            }, 300);
          }
        }
      }
    }

    if (slideCount > 1) {
      ResponsiveHelper.addRange({
        '..767': {
          on: () => {
            initDestroySwiper();
            options.effect = 'fade';
            options.loop = true;
            isMobile = true;

            setTimeout(() => {
              initSwiper()
            }, 1000);
          }
        },
        '768..1023': {
          on: () => {
            initDestroySwiper();
            options.parallax = false;
            options.loop = false;
            options.effect = 'slide';
            isMobile = false;

            setTimeout(() => {
              initSwiper()
            }, 1000);
          }
        },
        '1024..': {
          on: () => {
            initDestroySwiper();
            options.parallax = true;
            options.loop = false;
            options.effect = 'slide';
            isMobile = false;

            setTimeout(() => {
              initSwiper()
            }, 1000);
          }
        }
      });

      function initSwiper() {
        swiper = new Swiper(slider, options);
      }

      const nextButton = slider.querySelector('.swiper-button-next');

      if (nextButton) {
        nextButton.addEventListener('click', function() {
          if (nextButton.classList.contains('swiper-button-disabled') && isLastSlide) {
            setTimeout(() => {
              swiper.slideTo(0);
              isLastSlide = false;
            }, 100);
          }
        });
      }

      function initDestroySwiper() {
        if (swiper) {
          swiper.destroy();
          swiper = null;
          isLastSlide = false;
        }
      }

      function updateDotsPosition() {
        if (window.innerWidth < 768) {
          const imgBlock = slider.querySelector('.left-col .img-block');
          const slickDots = slider.querySelector('.swiper-pagination');
          const sliderSection = imgBlock.closest('.multi-slider-section');

          const sliderSectionTop = sliderSection.getBoundingClientRect().top + window.scrollY;
          const imgBlockTop = imgBlock.getBoundingClientRect().top + window.scrollY;
          const spaceBetween = imgBlockTop - sliderSectionTop;
          const imgBlockHeight = imgBlock.offsetHeight;
          const newTopPosition = spaceBetween + imgBlockHeight;
          slickDots.style.top = `${newTopPosition}px`;
        } else {
          const slickDots = slider.querySelector('.swiper-pagination');
          if (slickDots) {
            slickDots.style.top = '';
          }
        }
      }

      setTimeout(function() {
        updateDotsPosition()
      }, 0)

      window.addEventListener('resize', function() {
        setTimeout(function() {
          updateDotsPosition()
        }, 100)
      });

      window.addEventListener('orientationchange', function() {
        setTimeout(function() {
          updateDotsPosition()
        }, 100)
      });
    }

    if (slideCount <= 1) {
      jQuery(slider).closest('.multi-slider-section').addClass('single-slide');
    }
  });
}
