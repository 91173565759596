/* eslint-disable */
/*
 * Popups plugin
 */
const trapFocus = (element, prevFocusableElement = document.activeElement) => {
  const focusableEls = Array.from(
    element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    )
  );

  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];
  let currentFocus = null;

  firstFocusableEl.focus();
  currentFocus = firstFocusableEl;

  const handleFocus = e => {
    e.preventDefault();

    if (focusableEls.includes(e.target)) {
      currentFocus = e.target;
    } else {
      if (currentFocus === firstFocusableEl) {
        lastFocusableEl.focus();
      } else {
        firstFocusableEl.focus();
      }

      currentFocus = document.activeElement;
    }
  };

  document.addEventListener('focus', handleFocus, true);

  return {
    onClose: () => {
      document.removeEventListener('focus', handleFocus, true);
      prevFocusableElement.focus();
    }
  };
};

(function($) {
  function ContentPopup(opt) {
    this.options = $.extend({
      holder: null,
      popup: '.popup',
      btnOpen: '.open',
      btnClose: '.close',
      openClass: 'popup-active',
      popupOpenClass: 'open-popup',
      clickEvent: 'click',
      mode: 'click',
      hideOnClickLink: true,
      hideOnClickOutside: true,
      delay: 50
    }, opt);

    if (this.options.holder) {
      this.holder = $(this.options.holder);
      this.init();
    }
  }

  ContentPopup.prototype = {
    init: function() {
      this.findElements();
      this.attachEvents();
    },
    findElements: function() {
      this.doc = $(document);
      this.win = $(window);
      this.btnOpen = this.holder.find(this.options.btnOpen);
      this.popup = $(this.btnOpen.data('popup'));
      this.btnClose = this.popup.find(this.options.btnClose);
    },
    attachEvents: function() {
      var self = this;

      this.clickMode = isTouchDevice || (self.options.mode === self.options.clickEvent);

      if (this.clickMode) {
        // handle click mode
        this.btnOpen.bind(self.options.clickEvent + '.popup', function(e) {
          if (self.holder.hasClass(self.options.openClass)) {
            if (self.options.hideOnClickLink) {
              self.hidePopup();
            }
          } else {
            self.showPopup();
          }

          e.preventDefault();
        });

        // prepare outside click handler
        this.outsideClickHandler = this.bind(this.outsideClickHandler, this);
      } else {
        // handle hover mode
        var timer,
          delayedFunc = function(func) {
            clearTimeout(timer);

            timer = setTimeout(function() {
              func.call(self);
            }, self.options.delay);
          };

        this.btnOpen.on('mouseover.popup click', function() {
          delayedFunc(self.showPopup);
        }).on('mouseout.popup', function() {
          delayedFunc(self.hidePopup);
        });

        this.popup.on('mouseover.popup', function() {
          delayedFunc(self.showPopup);
        }).on('mouseout.popup', function() {
          delayedFunc(self.hidePopup);
        });
      }

      this.doc.on('keyup', this.btnOpen, (e) => {
        var focusedItem = jQuery(document.activeElement);
        var inModal = focusedItem.closest('.popup').length ? true : false;

        if (e.keyCode === 13 && inModal === false) {
          this.trapped = trapFocus(this.popup[0]);
        }

        if (e.keyCode === 13 && inModal) {
          if (this.trapped) {
            this.trapped.onClose();
          }
        }

        if (e.keyCode === 9 && inModal === false && this.holder.hasClass(this.options.openClass)) {
          this.openModal = true;
        }
      });

      this.btnOpen.on('focus', () => {
        if (this.openModal) {
          this.hidePopup();

          if (this.trapped) {
            this.trapped.onClose();
          }

          this.openModal = false;
        }
      });

      this.popup.on('keyup', (e) => {
        switch (e.keyCode) {
          case 27:
            this.hidePopup();

            break;

          default:
            break;
        }
      });
    },
    outsideClickHandler: function(e) {
      // hide popup if clicked outside
      var targetNode = $((e.changedTouches ? e.changedTouches[0] : e).target);

      if (!targetNode.closest(this.popup).length && !targetNode.closest(this.btnOpen).length) {
        this.hidePopup();
      }
    },
    showPopup: function() {
      // reveal popup
      this.holder.addClass(this.options.openClass);

      this.popup.addClass(this.options.popupOpenClass).css({
        top: this.btnOpen.offset().top - this.popup.outerHeight() - 17,
        left: this.btnOpen.offset().left + (this.btnOpen.outerWidth() / 2)
      });

      // outside click handler
      if (this.clickMode && this.options.hideOnClickOutside && !this.outsideHandlerActive) {
        this.outsideHandlerActive = true;
        this.doc.on('click touchstart', this.outsideClickHandler);
      }
    },
    hidePopup: function() {
      // hide popup
      this.holder.removeClass(this.options.openClass);
      this.popup.removeClass(this.options.popupOpenClass);

      // outside click handler
      if (this.clickMode && this.options.hideOnClickOutside && this.outsideHandlerActive) {
        this.outsideHandlerActive = false;
        this.doc.off('click touchstart', this.outsideClickHandler);
      }

      if (this.trapped) {
        this.trapped.onClose();
      }
    },
    bind: function(f, scope, forceArgs) {
      return function() {
        return f.apply(scope, forceArgs ? [forceArgs] : arguments);
      };
    }
  };

  // detect touch devices
  var isTouchDevice = /Windows Phone/.test(navigator.userAgent) || ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;

  // jQuery plugin interface
  $.fn.contentPopup = function(opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function() {
      var $holder = jQuery(this);
      var instance = $holder.data('ContentPopup');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data('ContentPopup', new ContentPopup($.extend({
          holder: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
}(jQuery));
