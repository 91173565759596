import '../plugins/accordionPlugin';

export default function initMainNavAccordion() {
  jQuery('.main-nav').slideAccordion({
    opener: '.has-drop-down-opener',
    slider: '.slide',
    animSpeed: 300,
    allowClickWhenExpanded: true,
    activeClass: 'slide-active',
    beforeToggle: function() {
      this.$holder.closest('.main-nav-drop').addClass('subnav-active');
    }
  });
}
